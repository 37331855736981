* {
	box-sizing: border-box;
}

:root {
	--bodyBackground: #0e171a;
	--menuBackground: hsl(196, 63%, 33%);
	--searchTextColor: #71aec4;
	--searchActiveTextColor: #cff2ff;
	--listingHeaderBackground: #0f4052;
	--listingHeaderColor: rgb(162, 220, 241);
	--listingBackground: rgb(20, 40, 47);
	--listingColor: white;
	--markerStartColor: rgba(255, 0, 0, 1);
	--markerEndColor: rgba(255, 0, 0, 0);
	--markerOneThirdColor: rgba(255, 0, 0, .33);
	--markerTwoThirdColor: rgba(255, 0, 0, .66);
	--navColor: var(--listingColor);
	--navLines: rgba(255, 255, 255, 0.596);
	--searchBorder: #31515c;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

/* ============== Theme ============== */
.c0 {
	/* 0 is teil */
}

.c1 {
	/* 1 is blue */
	--bodyBackground: #0a1320;
	--menuBackground: hsl(216deg 70% 41%);
	--searchTextColor: #3c69ad;
	--searchActiveTextColor: #cfdbff;
	--listingHeaderBackground: hsl(216deg 70% 41%);
	--listingHeaderColor: hsl(216deg 100% 84%);
	--listingBackground: hsl(216deg 61% 19%);
	--searchBorder: #32435e;
}

.c2 {
	/* 2 is yellow */
	--bodyBackground: #161616;
	--menuBackground: #cedb3d;
	--searchTextColor: #c3c471;
	--searchActiveTextColor: #fcffcf;
	--listingHeaderBackground: #cedb3d;
	--listingHeaderColor: black;
	--listingBackground: #2a2a2a;
	--listingColor: white;
	--navColor: black;
	--navLines: rgb(0 0 0 / 21%);
	--searchBorder: #424242;
	--svgBtnFillColor: black;
}

.c3 {
	/* 3 is green */
	--bodyBackground: #181818;
	--menuBackground: #2a781c;
	--searchTextColor: #4a6e4f;
	--searchActiveTextColor: #cfffd3;
	--listingHeaderBackground: #2a781c;
	--listingHeaderColor: #dcffd6;
	--listingBackground: #282828;
	--searchBorder: #354f30;
}

.c4 {
	/* 4 is orange */
	--bodyBackground: #141414;
	--menuBackground: #e1a600;
	--searchTextColor: #b4a78b;
	--searchActiveTextColor: #fff4db;
	--listingHeaderBackground: #ffbc00;
	--listingHeaderColor: black;
	--listingBackground: #343434;
	--listingColor: white;
	--navLines: rgb(256 256 256 / 45%);
	--searchBorder: #4e4e4e;
	--svgBtnFillColor: rgb(0, 0, 0);
}

.c5 {
	/* 5 is black */
	--bodyBackground: #000000;
	--menuBackground: rgb(30 30 30);
	--searchTextColor: #7c7c7c;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: #606060;
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: rgb(24 24 24);
	--listingColor: rgb(197, 197, 197);
	--navLines: #323232;
	--searchBorder: #363636;
}

.c6 {
	/* 6 is white */
	--bodyBackground: #ebebeb;
	--menuBackground: rgb(255, 255, 255);
	--searchTextColor: #aaaaaa;
	--searchActiveTextColor: #181818;
	--listingHeaderBackground: #5c5c5c;
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: #fff;
	--listingColor: rgb(48, 48, 48);
	--navColor: var(--listingColor);
	--navLines: #cccccc;
	--searchBorder: #b9b9b9;
	--svgHeaderFill: #3b3b3b;
	--svgBtnFillColor: #fff;
}

.c7 {
	/* 7 is white with purple */
	--bodyBackground: #ebebeb;
	--menuBackground: rgb(255, 255, 255);
	--searchTextColor: #aaaaaa;
	--searchActiveTextColor: #181818;
	--listingHeaderBackground: linear-gradient(0deg, #542e68, #8a47ac);
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: #fff;
	--listingColor: rgb(48, 48, 48);
	--navColor: #7e2da8;
	--navLines: #cccccc;
	--searchBorder: #b9b9b9;
	--svgHeaderFill: #3b3b3b;
	--svgBtnFillColor: #fff;
}

.c8 {
	/* 8 is white with red */
	--bodyBackground: #ebebeb;
	--menuBackground: rgb(255, 255, 255);
	--searchTextColor: #aaaaaa;
	--searchActiveTextColor: #181818;
	--listingHeaderBackground: linear-gradient(0deg, #682e2e, #b43c3c);
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: #fff;
	--listingColor: rgb(48, 48, 48);
	--navColor: #982323;
	--navLines: #cccccc;
	--searchBorder: #b9b9b9;
	--svgHeaderFill: #3b3b3b;
	--svgBtnFillColor: #fff;
}

.c9 {
	/* 9 is white with blue */
	--bodyBackground: #ebebeb;
	--menuBackground: rgb(255, 255, 255);
	--searchTextColor: #aaaaaa;
	--searchActiveTextColor: #181818;
	--listingHeaderBackground: linear-gradient(0deg, #2e3868, hsl(230deg 64% 58%));
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: #fff;
	--listingColor: rgb(48, 48, 48);
	--navColor: #2e48c1;
	--navLines: #cccccc;
	--searchBorder: #b9b9b9;
	--svgHeaderFill: #3b3b3b;
	--svgBtnFillColor: #fff;
}

.c10 {
	/* 10 is blue teil gradients */
	--bodyBackground: linear-gradient(100deg, hsl(233deg 59% 46%), hsl(172deg 100% 50%));
	--menuBackground: linear-gradient(100deg, hsl(233deg 59% 46%), hsl(172deg 100% 50%));
	--searchTextColor: #ffffff7e;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: hsl(228deg 100% 18% / 48%);
	--listingHeaderColor: rgb(255 255 255);
	--listingBackground: rgb(7 7 70 / 15%);
	--listingColor: white;
	--navColor: var(--listingColor);
	--navLines: rgba(255, 255, 255, 0.596);
	--searchBorder: #ffffff50;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

.c11 {
	/* 10 is red yellow gradients */
	--bodyBackground: linear-gradient(100deg, hsl(15deg 73% 43%), hsl(52deg 100% 50%));
	--menuBackground: linear-gradient(100deg, hsl(15deg 73% 43%), hsl(52deg 100% 50%));
	--searchTextColor: #ffffff7e;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: hsl(7deg 75% 27% / 59%);
	--listingHeaderColor: rgb(255 255 255);
	--listingBackground: rgb(70 7 7 / 15%);
	--listingColor: white;
	--navColor: var(--listingColor);
	--navLines: rgba(255, 255, 255, 0.596);
	--searchBorder: #ffffff50;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

.c12 {
	/* 10 is purple pink gradients */
	--bodyBackground: linear-gradient(105deg, hsl(277deg 90% 38%), hsl(300deg 100% 75%));
	--menuBackground: linear-gradient(105deg, hsl(277deg 90% 38%), hsl(300deg 100% 75%));
	--searchTextColor: #ffffff7e;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: hsl(270deg 84% 23% / 41%);
	--listingHeaderColor: rgb(255 255 255);
	--listingBackground: rgb(45 7 70 / 10%);
	--listingColor: white;
	--navColor: var(--listingColor);
	--navLines: rgba(255, 255, 255, 0.596);
	--searchBorder: #ffffff50;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

.c13 {
	/* 10 is blue gray gradients */
	--bodyBackground: linear-gradient(100deg, hsl(197deg 20% 31%), hsl(197deg 56% 77%));
	--menuBackground: linear-gradient(100deg, hsl(197deg 20% 31%), hsl(197deg 56% 77%));
	--searchTextColor: #ffffff7e;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: hsl(198deg 100% 13% / 32%);
	--listingHeaderColor: rgb(255 255 255);
	--listingBackground: rgb(6 41 56 / 12%);
	--listingColor: white;
	--navColor: var(--listingColor);
	--navLines: rgba(255, 255, 255, 0.596);
	--searchBorder: #ffffff50;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

.c14 {
	/* 10 is black gray gradients */
	--bodyBackground: linear-gradient(114deg, hsl(0deg 0% 7%), hsl(0deg 0% 18%));
	--menuBackground: linear-gradient(114deg, hsl(0deg 0% 4%), hsl(0deg 0% 15%));
	--searchTextColor: #ffffff7e;
	--searchActiveTextColor: #ffffff;
	--listingHeaderBackground: hsl(0deg 0% 100% / 11%);
	--listingHeaderColor: rgb(255 255 255);
	--listingBackground: rgb(0 0 0 / 12%);
	--listingColor: white;
	--navColor: var(--listingColor);
	--navLines: rgb(255 255 255 / 13%);
	--searchBorder: #ffffff50;
	--svgHeaderFill: white;
	--svgBtnFillColor: white;
}

.c15 {
	/* 15 is white/web colors */
	--bodyBackground: #f5f5f5;
	--menuBackground: rgb(255, 255, 255);
	--searchTextColor: #aaaaaa;
	--searchActiveTextColor: #181818;
	--listingHeaderBackground: #5c5c5c;
	--listingHeaderColor: rgb(255, 255, 255);
	--listingBackground: #fff;
	--listingColor: rgb(48, 48, 48);
	--navColor: var(--listingColor);
	--navLines: #cccccc;
	--searchBorder: #b9b9b9;
	--svgHeaderFill: #3b3b3b;
	--svgBtnFillColor: #fff;
}


.themeBtn {
	position: absolute;
	bottom: 1em;
	left: calc(50% - 40px);
	padding: 1em;
	color: white;
	z-index: 999;
	background: rgba(0, 0, 0, 0.363);
	border: solid 1px white;
	border-radius: 1em;
	cursor: pointer;
}



html,
body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: OpenSans600, sans-serif;
	font-size: 13px;
}

a,
a:visited,
a:active,
a:hover {
	color: var(--listingColor);
	text-decoration: none;
}

.container {
	position: fixed;
	color: var(--listingColor);
	background: var(--bodyBackground);
}




/* ============== Header ============== */
.header {
	position: absolute;
	display: grid;
	grid-template-rows: 15% 1fr 15%;
	grid-template-columns: 3em 1fr 2.5em 35px 62px;
	top: 0;
	width: 100%;
	height: 3em;
	padding-left: .5em;
	z-index: 2;
}

.searchIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: 2 / 1 / 3 / 2;
	cursor: pointer;
	-webkit-tap-highlight-color: var(--menuBackground);
}

.searchIcon svg {
	fill: var(--svgHeaderFill);
	width: 1.7em;
}

.searchInputCont {
	display: flex;
	grid-area: 2 / 2 / 3 / 4;
	width: 100%;
	overflow: hidden;
	transition: .4s all;
	cursor: text;
}

.searchInput {
	width: 100%;
	padding: 0 1em 0 0;
	font-size: 16px;
	color: var(--searchActiveTextColor);
	text-transform: uppercase;
	border: none;
	border-radius: 0;
	outline: none;
	background: transparent;
	border-bottom: solid 1px var(--searchBorder);
}

.searchInputCont.idle {
	width: 0%;
}

input[type="search"] {
	-webkit-appearance: none;
}

::-webkit-input-placeholder {
	/* Edge */
	color: var(--searchTextColor);
	text-transform: none;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--searchTextColor);
	text-transform: none;
}

::placeholder {
	color: var(--searchTextColor);
	text-transform: none;
}

.searchClear {
	display: flex;
	grid-area: 2 / 3 / 3 / 4;
	align-items: center;
	justify-content: center;
	color: #252525;
	cursor: pointer;
	z-index: 5;
}

.clearIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.4em;
	height: 1.4em;
	font-size: .85em;
	border-radius: 50%;
	opacity: 1;
	transition: all .35s;
}

.clearIconBright {
	background: #b3b3b3;
	background: #3d6dab;
	background: #92bdcc;
}

.clearIcon svg {
	fill: var(--searchTextColor);
}

.hideIcon {
	opacity: 0;
}

.instructionText {
	width: 100%;
	font-size: .7em;
	padding-left: .25em;
	padding-top: .25em;
}

/* ============== Navigation ============== */
.nav {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: .2em;
	width: 55px;
	height: 50px;
	opacity: 1;
	cursor: pointer;
	z-index: 20;
	transition: all .4s;
	-webkit-tap-highlight-color: var(--menuBackground);
}

.tsLogoContainer {
	position: relative;
	color: white;
	transition: .4s all;
}

.tsLogoContainer.loaded {
	color: transparent;
}

.tsLogo {
	position: absolute;
	left: 0;
	width: 7em;
	opacity: 0;
	transition: .4s all;
}

.tsLogo.loaded {
	opacity: 1;
}

/* Animated Hamburger Menu - https://codepen.io/designcouch/pen/Atyop */
#navIcon {
	width: 30px;
	height: 22px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

#navIcon span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: var(--svgHeaderFill);
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

#navIcon span:nth-child(1) {
	top: 0px;
}

#navIcon span:nth-child(2),
#navIcon span:nth-child(3) {
	top: 10px;
}

#navIcon span:nth-child(4) {
	top: 20px;
}

#navIcon.open span:nth-child(1) {
	top: 18px;
	width: 0%;
	left: 50%;
}

#navIcon.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#navIcon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#navIcon.open span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%;
}

.logoWrapper {
	display: flex;
	justify-content: center;
}

.logo {
	background: transparent;
}

.menu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 3em 0;
	background: var(--menuBackground);
	overflow: hidden;
	transition: all .2s ease-in-out;
	z-index: 10;
	overflow-y: auto;
}

.menuWrapper {
	width: 100vw;
	padding: 0 3em;
}

.navLine {
	height: 1px;
	background: var(--navLines);
}

.btn {
	padding: .5em 0;
	font-size: 2em;
	color: var(--navColor);
	cursor: pointer;
	-webkit-tap-highlight-color: var(--menuBackground);
}

.noTransition {
	transition: none;
}

.hideNav {
	display: none;
}

.slide {
	width: 0;
}

.loadScreen {
	display: flex;
	opacity: 1;
	transition: all .8s;
}

.loadContent {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loadHide {
	display: none;
}

.opacity0 {
	opacity: 0;
}


/* ============= Listings ============= */
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.hasWayfinding .container {
	filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 20%));
}

.listings {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 3em;
	width: 100%;
	height: calc(100% - 3em);
	overflow-x: hidden;
	overflow-y: auto;
}

.listHeader {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 5px 7px;
	padding: .8em 1.2em;
	color: var(--listingHeaderColor);
	background: var(--listingHeaderBackground);
	border-radius: 0.5em;
	opacity: 1;
	transition: all .3s;
}

.listItemSelected .listHeader {
	height: 0;
	padding-top: 0;
	padding-bottom: 0;
	opacity: 0;
}

.listItemSelected .listHeader {
	display: none;
}

.listHeader0,
.listHeader1,
.listHeader2 {
	padding: 0 .25em
}

.listHeader0 {
	display: flex;
	align-items: center;
	text-align: left;
}

.listHeader1 {
	text-align: center;
}

.listHeader2 {
	text-align: center;
}

.wayfinding {
	padding: .5em .2em 1.1em .2em;
}

.wfTouchInst {
	position: absolute;
	left: 7px;
	top: 26px;
	width: 200px;
	font-size: 11px;
	color: #86c7df;
}

.listGroup {
	width: 100%;
	word-break: break-word;
	z-index: 1;
}

.listItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: .6em;
	padding: .9em 1.2em;
	color: var(--listingColor);
	border-radius: 0.5em;
	background: var(--listingBackground);
	line-height: 1.4em;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

.listItem.selected {
	max-height: 42vh;
	flex-direction: column;
	align-items: normal;
	margin-bottom: .75em;
	scroll-behavior: auto;
	overflow-y: scroll;
}

.scrollGradientMask {
	content: '';
	position: absolute;
	top: calc(42vh - 2.4em);
	left: 0;
	width: 100%;
	height: 3em;
	background: linear-gradient(to top, var(--bodyBackground) 0%, transparent 100%);
	pointer-events: none;
}

.unSelected .scrollGradientMask {
	display: none;
}

.phoneOn {
	padding: .7em .5em;
}

.hasEvent:active {
	background: var(--menuBackground);
}

.hasEvent.selected {
	background: var(--listingBackground);
}

.lineWrap {
	display: flex;
	padding: .7em 0;
	flex-direction: column;
	pointer-events: none;
}

.listItemRow {
	display: block;
}

.data0 {
	margin-bottom: .3em;
	font-size: 1.3em;
	font-weight: bold;
	text-align: left;
	pointer-events: none;
}

.data1,
.data2,
.data3,
.data4 {
	display: inline-block;
	text-align: left;
	pointer-events: none;
}

.dataFieldName {
	display: inline-block;
	margin-right: .2em;
}

.listHeader1,
.listHeader2,
.listHeader3,
.listHeader4 {
	display: none;
}

.phoneCont {
	position: absolute;
	right: .8em;
	background: var(--listingHeaderBackground);
	border-radius: 0.3em;
	width: 1.6em;
	height: 1.45em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -0.2em;
	margin-left: -0.1em;
	margin-right: .3em;
}

.phoneIcon {
	width: 1em;
	height: 1em;
	fill: var(--svgBtnFillColor);
}

.phoneSpacer {
	width: 1.6em;
	height: 1em;
	margin-right: .3em;
}

/* ============== Listing Buttons ============== */
.listBtnsRow {
	position: relative;
	bottom: -0.7em;
	display: flex;
	justify-content: center;
	pointer-events: none;
}

.listBtns {
	overflow: hidden;
	height: 0;
	width: calc(100% - 6em);
	transition: all .3s;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	background: rgb(0 0 0 / 8%);
	border: solid 0px transparent;
}

.listItem.open .listBtns {
	height: 3.5em;
	border-radius: .5em;
	margin-bottom: 1em;
}

.newClass {
	padding: .5em 2em;
	background: #006e94;
	margin: .5em .8em;
	border-radius: 5px;
	display: block;
}

/* ============== Messages ============== */
.noMatch {
	text-align: center;
	font-size: 2em;
	padding: 1em;
}

.loadText {
	height: 100%;
	background: rgba(0, 0, 0, 0.89);
	position: absolute;
	width: 100%;
	text-align: center;
	font-size: 2em;
	padding-top: 30%;
	position: fixed;
	opacity: 1;
	transition: all .3s ease-in-out;
}

.error,
.mapError {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
	top: 0;
	left: 0;
	padding: 1em;
	font-size: 2em;
	line-height: 1.5;
	background: rgb(24, 24, 24);
	z-index: 20;
}

.errorBtn {
	background: #8a8e0e;
	margin-top: 2em;
	padding: .1em 1em;
	color: white;
	border-radius: 12px;
}

.error2 {
	background: #86470b;
}

.mapError {
	background: rgba(0, 0, 0, 0.87);
}

/* ================= Wayfinding ================= */
#wayfindingModal {
	display: none;
	font-size: 16px;
	overflow: hidden;
	width: 100%;
	touch-action: none;
}

.hasWayfinding #wayfindingModal {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	bottom: 0;
	left: 0;
	width: 100vw;
	background: transparent;
	z-index: 0;
	color: white;
	pointer-events: auto;
	cursor: grab;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.hasWayfinding #wayfindingModal.hideModal {
	opacity: 0;
	pointer-events: none;
	display: none;
}

.wayfindingContainer {
	position: relative;
	flex-direction: column;
	width: 100vw;
	height: 100%;
	background: transparent;
	transform-origin: center center;
	touch-action: none;
}

.zoomBtnContainer {
	position: fixed;
	bottom: 1em;
	right: 1em;
	display: none;
	flex-direction: column;
	background-color: white;
	border-radius: .5em;
	overflow: hidden;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
	z-index: 99;
}

.refreshBtnContainer {
	position: fixed;
	bottom: 7em;
	right: 1em;
	display: none;
	flex-direction: column;
	background-color: white;
	border-radius: .5em;
	overflow: hidden;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
	z-index: 99;
}

.hasWayfinding .refreshBtnContainer,
.hasWayfinding .zoomBtnContainer {
	display: flex;
}

.zoomOut,
.zoomIn {
	width: 1.8em;
	height: 1.8em;
	background-color: white;
	border: none;
	color: #444;
	font-family: 'OpenSans500';
	font-size: 1.5em;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s;
}

.zoomOut:active,
.zoomIn:active {
	background-color: #c3c3c3;
	color: #fff;
}

.zoomOut:hover,
.zoomIn:hover {
	background-color: #dadada;
}

.refreshBtn {
	width: 1.8em;
	height: 1.8em;
	background-color: white;
	border: none;
	color: #444;
	font-family: 'OpenSans500';
	font-size: 1.5em;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s;
}

.refreshBtn:active {
	background-color: #c3c3c3;
	color: #fff;
}

.refreshBtn:hover {
	background-color: #dadada;
}

#wayfindingHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 4rem;
	padding: 0 3.5rem 0 1rem;
	color: var(--listingHeaderColor);
	background: var(--listingHeaderBackground);
	font-weight: 700;
	color: white;
	display: none;
}

.wayfindingHeaderRight {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.urlBtn {
	display: flex;
	align-items: center;
	margin-right: .8em;
	padding: .5em .9em;
	font-weight: 500;
	border: solid 1px rgba(255, 255, 255, 0.5);
	border-radius: .5em;
	background: rgb(0, 85, 103);
	transition: all .2s;
}

.urlBtn:hover {
	background: rgb(0 104 126);
}

.headerIcon {
	margin-right: .4em;
	fill: var(--svgHeaderFill);
}

.closeBtn {
	position: absolute;
	height: 2rem;
	width: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 1rem;
	right: .5rem;
	color: var(--listingHeaderBackground);
	background-color: var(--listingHeaderColor);
	border-radius: 5rem;
	font-size: 1.5rem;
}

/* ================= Misc ================= */
.hide {
	opacity: 0;
	pointer-events: none;
}

.show {
	opacity: 1;
	pointer-events: none;
}

/* ================= Color Picker ================= */
.colorPickerBtn {
	position: fixed;
	bottom: 10px;
	right: 0;
	width: 40px;
	height: 50px;
	cursor: pointer;
	z-index: 100;
}

.circle {
	position: absolute;
	height: 12px;
	left: 20px;
	background: #1e6d89;
	width: 12px;
	border-radius: 50%;
}

.circle1 {
	opacity: .3;
}

.circle2 {
	top: 14px;
	opacity: .6;
}

.circle3 {
	top: 28px;
	opacity: .9;
}

.colorPicker {
	position: fixed;
	bottom: 0;
	height: 0px;
	display: flex;
	padding: 0 36px 0 0;
	z-index: 50;
	overflow: hidden;
	transition: all .3s .3s;
	background: #00000082;
	align-items: center;
	width: 100%;
	justify-content: flex-end;
}

.pickerOpen {
	height: 80px;
}

.swatch {
	width: 41px;
	height: 52%;
	margin: 5px 5px;
	background: hsl(196, 63%, 33%);
	border-radius: 8px;
}

/* Web Mobile Styles - c15 */

.c15 .listItem {
	border-bottom: 1px solid #dddbdb;
	border-radius: 0;
}

.c15 .listHeader {
	border-radius: .2em;
}

.c15 .replayButton,
.c15 .websiteButton,
.c15 .categoryBtn {
	background: var(--bodyBackground);
}

.c15 .replayButton:active,
.c15 .websiteButton:active,
.c15 .categoryBtn:active {
	background: var(--bodyBackground);
}

.listings.listItemSelected .listItem.unSelected {
	display: none;
}

.unSelected .closeListItem {
	display: none;
}

.selected .closeListItem {
	display: flex;
	height: 0;
	font-size: 1.6em;
	justify-content: flex-end;

}

.unSelected .listDetailsBtnsRow {
	width: 0;
}

.listDetailsBtnsRow {
	display: flex;
}

.replayButton,
.websiteButton,
.map,
.call,
.wayfindingBtn,
.categoryBtn {
	display: flex;
	width: 7em;
	height: 2.5em;
	padding: 0.2em;
	margin-right: 0.8em;
	align-items: center;
	background: var(--listingHeaderBackground);
	color: var(--listingColor);
	border-radius: .5em;
	font-weight: bold;
	justify-content: center;
	/* transition: height .4s ease-in-out; */
}

.replayButton:active,
.websiteButton:active {
	background: var(--listingHeaderBackground);
}

.storeLogo {
	max-width: 3em;
	max-height: 3em;
	margin: 0 .8em 0 0;
	border-radius: .5em;
	pointer-events: none;
}

.storeLogoPlaceholder {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3em;
	height: 3em;
	margin: 0 .8em 0 0;
	border-radius: .5em;
	background: #e5e5e5;
	pointer-events: none;
	flex-shrink: 0;
}

.storeLogoPlaceholder i {
	font-size: 1.3em;
	color: #686868;
}

.detailsText {
	display: flex;
	height: auto;
	padding: 1em 0 0.5em 0;
	pointer-events: none;
}

.detailsText .categoryBtn {
  pointer-events: auto;
}

a.websiteButton,
a:visited.websiteButton {
	color: var(--listingColor);
}

.websiteButton svg {
	fill: var(--listingColor);
}

.unSelected .replayButton,
.unSelected .websiteButton,
.unSelected .map,
.unSelected .call,
.unSelected .wayfindingBtn {
	opacity: 0;
	height: 0;
	width: 0;
	transition: height 0.5s ease-in-out;
	pointer-events: none;
	/* Disable interactions when hidden */
}

.unSelected .detailsText {
	display: none;
	height: 0;
	padding: 0;
}

.hasWayfinding .wayfindingContainer {
	width: 100%;
}

/* Categories */
.categories {
	flex-direction: column;
}
.categoriesHeader {
	margin-bottom: .6em;
    font-size: 1.3em;
    font-weight: bold;
}
.categoryBtnWrapper {
	display: flex;
	flex-wrap: wrap;
}
.categoryBtn {
	width: auto;
	margin: .3em .3em .3em 0;
    padding: .2em 1em;
	border-radius: 2em;
}

.category-dropdown {
    position: absolute;
    top: 86%;
    left: 9%;
    width: 75%;
    max-height: 60vh;
    background-color: var(--listingBackground);
    border: 1px solid var(--navLines);
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    display: none;
    overflow: auto;
}

.category-item {
	margin: 0 1em;
    padding: 1em;
	border-bottom: solid 1px var(--navLines);
    cursor: pointer;
}

.category-item:hover {
    background-color: var(--bodyBackground);
}


/* loading screen */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 105px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #424242;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 42px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 76px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(34px, 0);
	}
}

@media (orientation: landscape) {
	body.hasWayfinding {
		pointer-events: none;
	}

	.hasWayfinding .loadText {
		display: none;
	}

	.hasWayfinding .menu {
		width: 40%;
		filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 20%));
	}

	.hasWayfinding .searchInputCont.idle {
		width: 100%;
	}

	.hasWayfinding .nav {
		position: absolute;
	}

	.hasWayfinding .container {
		width: 40%;
		height: 100%;
		filter: none;
		pointer-events: auto;
	}

	.listings.listItemSelected {
		height: calc(100% - 3em);
	}

	.listItem.selected {
		max-height: none;
		scroll-behavior: auto;
		overflow-y: auto;
	}

	.hasWayfinding .slide {
		width: 0;
	}

	.hasWayfinding #wayfindingModal {
		position: fixed;
		top: 0;
		left: 40%;
		width: 60%;
		z-index: -1;
	}

	.hasWayfinding #wayfindingHeader {
		display: none;
	}

	.hasWayfinding #wayfindingModal.hideModal {
		opacity: 1;
		pointer-events: auto;
		display: flex;
	}

	.initialLoad .wayfindingMapImg {
		opacity: 1;
	}

	.scrollGradientMask {
		display: none;
	}
}

@media (min-width: 1024px) and (orientation: landscape) {

	/* Small font size for very small phones, horizontal */
	body {
		font-size: 12px;
	}
}

@media (orientation: portrait) {
	.listings {
		top: 3.5em;
	}
}

@media (min-width: 1024px) and (orientation: portrait) {

	/* Larger font sizes for vertical screens */
	body {
		font-size: 16px;
	}
}

@media (max-width: 1279px) and (orientation: landscape) {
	.nav {
		transform: scale(.8);
		transform-origin: top right;
	}
}

/* Desktop screen */
@media (min-width: 1024px) {
	.storeLogo {
		max-width: 5em;
		max-height: 5em;
	}

	.storeLogoPlaceholder {
		width: 5em;
		height: 5em;
	}

	.storeLogoPlaceholder i {
		font-size: 2em;
	}

	.listItem.selected .storeLogo {
		max-width: 10em;
		max-height: 10em;
	}
}

/* Horizontal desktop screen */
@media (min-width: 1280px) and (orientation: landscape) {
	body {
		font-size: 16px;
	}

	.hasWayfinding .menu {
		width: 30%;
	}

	.hasWayfinding .slide {
		width: 0;
	}

	.hasWayfinding .container {
		width: 30%;
	}

	.hasWayfinding #wayfindingModal {
		left: 30%;
		width: 70%;
	}
}

/* Open Sans 600 */
@font-face {
	font-family: 'OpenSans600';
	font-style: normal;
	font-weight: 600;
	src:
		url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600.woff2') format('woff2'),
		url('https://s3.amazonaws.com/ts-condor-assets/fonts/open-sans-v17-latin-600.woff') format('woff')
}

@media (orientation: portrait) {

	.hasWayfinding .refreshBtnContainer,
	.hasWayfinding .zoomBtnContainer {
		display: none;
	}

	.hasWayfinding .listItemSelected .zoomBtnContainer {
		display: flex;
	}

	.hasWayfinding .listItemSelected .refreshBtnContainer {
		display: flex;
	}
}