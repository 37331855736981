#wayfindingMapContainer {
	position: absolute;
	opacity: 1;
	transition: opacity .3s;
}

.wayfindingGraphicsContainer {
	opacity: 0;
	transition: opacity .4s ease-in-out;
}
.canvasArea {
	position: absolute;
	opacity: 0;
	transition: opacity .4s ease-in-out;
}

#wayfindingMapImg {
	width: 100%;
}

.mapGraphic {
	position: absolute;
	opacity: 0;
	transition: opacity .4s ease-in-out;
}

.wayfindingMsgCont {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 1.8em;
	color: black;
	background: rgba(255, 255, 255, 0.9);
	z-index: 2000;
}

.wayfindingMsgText {
	margin: .5em;
	padding: .8em 1.5em;
	border: solid 1px gray;
	background: white;
}
.instructionCont {
	position: absolute;
	display: flex;
	justify-content: center;
	bottom: 0;
	width: 100%;
	height: 10em;
	overflow: hidden;
}
.instructions {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: -4em;
	max-width: 80%;
	min-width: 40%;
	padding: .5em 1em;
	font-size: 2em;
	color: #ffffff;
	font-family: OpenSans500, sans-serif;
	text-align: center;
	line-height: 1.2;
	background: #38526a;
	border-radius: .5em .5em 0 0;
	opacity: 0;
	z-index: 9999;
	transition: all .3s ease-in-out;
}
.showInstructions {
	bottom: 0;
	opacity: .9;
}
.matchingLocations {
	display: none;
	flex-wrap: wrap;
	position: relative;
	top: 0;
}
.matchingLocationsShow {
	display: flex;
}
.matchingLocationsText {
	width: 100%;
	margin: 2em 0 .5em 0;
}
.matchingLocationBtn {
	display: inline-block;
	margin: 0 1em .4em 0;
    padding: 0.5em 1em;
	background: black;
	color: var(--listingColor);;
    text-align: center;
	border-radius: 2em;
	background: var(--listingHeaderBackground);
	cursor: pointer;
}
.graphicText {
	font-family: OpenSans600, sans-serif;
	opacity: 0;
	transition: opacity .4s;
}
.graphicIcon,
.graphicImage {
	opacity: 0;
	transition: opacity .4s;
}
.nearbyLocations,
.closeListItem,
.storeLogoPlaceholder,
.lineWrap,
.listDetailsBtnsRow,
#wayfindingMapContainer {
	opacity: 1;
	transition: opacity .25s;
}
.nearbyLocationDelay .nearbyLocations,
.nearbyLocationDelay .closeListItem,
.nearbyLocationDelay .storeLogoPlaceholder,
.nearbyLocationDelay .lineWrap,
.nearbyLocationDelay .listDetailsBtnsRow,
.nearbyLocationDelay #wayfindingMapContainer {
	opacity: 0 !important;
	transition: opacity 0s !important;
}

/* Map Loading */
.mapLoading {
	opacity: 0;
}

.mapLoaded {
	opacity: 1;
}

.loadingMessage {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 2em;
	color: hsl(0deg 0% 0% / 60%);
	opacity: 1;
	transition: opacity .7s ease-in-out;
	z-index: 50;
}

.loadingMessage.mapLoaded {
	opacity: 0;
}

.loadingText {
	margin-left: .5em;
	opacity: 0;
}

.loadingIconCont {
	opacity: 0;
}

.loadingIcon {
	font-size: 1.7em;
	transform-origin: center;
	animation: spin 1.5s linear infinite;
}

.mapLoading .loadingIconCont {
	animation: fadeIn 2s linear;
	animation-fill-mode: forwards;
}

.mapLoading .loadingText {
	animation: fadeIn 2s linear;
	animation-fill-mode: forwards;
}
.matchingLocationLabelCont {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;
}
.matchingLocationLabelOuter {
	border-radius: .6em;
	transform-origin: 0 50%;
}
.matchingLocationLabelInner {
	font-size: 0.5em;
	color: white;
	text-align: center;
	padding: .3em 1em;
	white-space: nowrap;
}

/* Prevent text selection while dragging */
.noselect {
	user-select: none;
	/* For modern browsers */
	-webkit-user-select: none;
	/* For Safari */
	-moz-user-select: none;
	/* For older Firefox */
	-ms-user-select: none;
	/* For older IE */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


/* Animated Location Marker */
.locationMarker {
	animation: locationMarkerfadeIn .4s linear;
}
.concentricCircleOuter {
	position: absolute;
	border-radius: 50%;
}

.concentricCircleInner {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.concentricCirclesCenter {
	position: absolute;
	border-radius: 50%;
}

.concentricCircleOuter1 {
	animation: growThenFadeAway1 4s linear infinite;
}

.concentricCircleOuter2 {
	animation: growThenFadeAway2 4s linear infinite;
}

.concentricCircleOuter3 {
	animation: growThenFadeAway3 4s linear infinite;
}

.concentricCircleOuter4 {
	animation: growThenFadeAway4 4s linear infinite;
}

.concentricCircleInner1 {
	animation: growThenFadeAwayChild1 4s linear infinite;
}

.concentricCircleInner2 {
	animation: growThenFadeAwayChild2 4s linear infinite;
}

.concentricCircleInner3 {
	animation: growThenFadeAwayChild3 4s linear infinite;
}

.concentricCircleInner4 {
	animation: growThenFadeAwayChild4 4s linear infinite;
}

@keyframes locationMarkerfadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes growThenFadeAway1 {
	0% {
		opacity: 1;
		transform: scale(0);
	}

	75% {
		opacity: 0;
		transform: scale(1);
	}

	100% {
		opacity: 0;
	}
}

@keyframes growThenFadeAway2 {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	25% {
		opacity: 1;
		transform: scale(0);
	}

	100% {
		opacity: 0;
		transform: scale(1);
	}
}

@keyframes growThenFadeAway3 {
	0% {
		opacity: .33;
		transform: scale(.66);
	}

	25% {
		opacity: 0;
		transform: scale(1);
	}

	49.9% {
		opacity: 0;
		transform: scale(0);
	}

	50% {
		opacity: 1;
		transform: scale(0);
	}

	100% {
		opacity: .33;
		transform: scale(.66);
	}
}

@keyframes growThenFadeAway4 {
	0% {
		opacity: .66;
		transform: scale(.33);
	}

	50% {
		opacity: 0;
		transform: scale(1);
	}

	50.1% {
		transform: scale(0);
	}

	74.9% {
		transform: scale(0);
	}

	75% {
		opacity: 1;
		transform: scale(0);
	}

	100% {
		opacity: .66;
		transform: scale(.33);
	}
}

@keyframes growThenFadeAwayChild1 {
	0% {
		opacity: 1;
	}

	75% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes growThenFadeAwayChild2 {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes growThenFadeAwayChild3 {
	0% {
		opacity: .33;
	}

	25% {
		opacity: 0;
	}

	49.9% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: .33;
	}
}

@keyframes growThenFadeAwayChild4 {
	0% {
		opacity: .66;
	}

	50% {
		opacity: 0;
	}

	50.1% {}

	75% {
		opacity: 1;
	}

	100% {
		opacity: .66;
	}
}